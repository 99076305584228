import { Icon } from "@iconify/react"
import { useEffect } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Link, useNavigate, useOutletContext } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import MockupImage2 from "../../assets/banner/background-2.png"
import MockupImage1 from "../../assets/banner/background.png"
import Palette from "../utils/Palette"

import "@vidstack/react/player/styles/default/layouts/video.css"
import "@vidstack/react/player/styles/default/theme.css"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import {
    A11y,
    Autoplay,
    Keyboard,
    Navigation,
    Pagination,
    Scrollbar
} from "swiper/modules"

export default function HomePage({}) {
    const [isMobile, isTablet, lang, setLang] = useOutletContext()
    // const [ isTablet, setIsTablet] = useOutletContext()
    const navigate = useNavigate()

    useEffect(() => {
        console.log('isMobile:', isMobile, 'isTablet:', isTablet);
    }, [isMobile, isTablet]);
    
    const renderHeroSection = () => {
        return (
            <div
                style={{
                    width: "100%",
                    height: isMobile || isTablet ? '800px' : '812px',
                    color: "black",
                    backgroundImage: isMobile || isTablet ? `url("${MockupImage2}")` : `url("${MockupImage1}")`,
                    backgroundSize: isMobile ? "cover" : isTablet ? "cover" : "cover",
                    padding: isMobile ? '160px, 16px, 0px, 16px' : isTablet ? '160px, 36px, 0px, 36px' : "",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            >
                <Container
                    style={{
                        marginBottom: (isMobile || isTablet) ? 0 : "12rem",
                        marginTop: (isMobile || isTablet) ? "160px" : "200px",
                        marginLeft: (isMobile || isTablet) ? "" : "70px",
                    }}
                >
                    {lang.HomePage.hero.map((thumb) => {
                        return (
                            <>
                                <Col 
                                    className={(isMobile || isTablet) ? 'mt-5' : ''} 
                                    style={{padding: (isMobile || isTablet) ? '0.5rem' : '2.5rem'}} 
                                    // style={{padding: '2.5rem'}} 
                                    md={isMobile || isTablet ? 12 : 9}
                                >
                                    <h2
                                        style={{
                                            fontWeight: 700,
                                            fontSize: isMobile || isTablet ? "36px" : "60px",
                                            lineHeight: isMobile || isTablet ? "48px" : "60px",
                                            fontFamily: "Bitter",
                                        }}
                                    >
                                        {thumb.title}
                                    </h2>
                                    <div
                                        style={{padding: "3px 5px 0px 5px"}}
                                        className={"mt-3 mb-3"}
                                    >
                                        <p
                                            style={{
                                                fontWeight: 500,
                                                fontSize: isMobile || isTablet ? "16px" : "20px",
                                                lineHeight: isMobile || isTablet ? "24px" : "28px"
                                            }}
                                        >
                                            {thumb.description}
                                        </p>
                                    </div>
                                    {isMobile ? (
                                        <>
                                            <div
                                                style={{padding: "3px 0px 15px 5px", gap: "20px"}}
                                                className={"d-flex flex-row"}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        navigate('/contact')
                                                    }}
                                                    style={{
                                                        border: 0,
                                                        textAlign: 'center',
                                                        width: "145px",
                                                        height: '40px',
                                                        borderRadius: '24px',
                                                        backgroundColor: '#E60000',
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    {thumb.button1}
                                                </Button>
                                            </div>
                                            <div
                                                style={{padding: "3px 0px 15px 5px", gap: "16px"}}
                                                className={"d-flex flex-row"}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        navigate('/product/1')
                                                    }}
                                                    style={{
                                                        border: 0,
                                                        textAlign: 'center',
                                                        width: "177px",
                                                        height: '40px',
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        borderRadius: '24px',
                                                        backgroundColor: '#0A0A0A',
                                                        fontWeight: 500,
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    {thumb.button2}
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            style={{padding: "3px 0px 15px 5px", gap: isTablet ? "16px" : "20px"}}
                                            className={"d-flex flex-row"}
                                        >
                                            <Button
                                                onClick={() => {
                                                    navigate('/contact')
                                                }}
                                                style={{
                                                    border: 0,
                                                    textAlign: 'center',
                                                    width: isTablet ? "145px" : '180px',
                                                    height: '40px',
                                                    borderRadius: '24px',
                                                    backgroundColor: '#E60000',
                                                    fontWeight: 500,
                                                    fontSize: isTablet ? "16px" : "20px",
                                                    lineHeight: isTablet ? "24px" : "28px",
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {thumb.button1}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    navigate('/product/1')
                                                }}
                                                style={{
                                                    border: 0,
                                                    textAlign: 'center',
                                                    width: isTablet ? "177px" : '210px',
                                                    height: '40px',
                                                    fontSize: isTablet ? "16px" : "20px",
                                                    lineHeight: isTablet ? "24px" : "28px",
                                                    borderRadius: '24px',
                                                    backgroundColor: '#0A0A0A',
                                                    fontWeight: 500,
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {thumb.button2}
                                            </Button>
                                        </div>
                                    )}
                                </Col>
                            </>
                        )
                    })}
                </Container>
            </div>
        )
    }
    const renderAboutUsSection = () => {
        return (
            <div>
                <Container
                    style={{
                        marginTop: isMobile ? '48px' : isTablet ? '48px' : '80px',
                        marginBottom: isMobile ? '48px' : isTablet ? '48px' : '80px',
                    }}
                >
                    <Row>
                        <Col md={isTablet || isMobile ? 12 : 7} className={"d-flex flex-column"}>
                            <h3
                                style={{
                                    fontWeight: 700,
                                    fontSize: (isMobile || isTablet) ? '24px' : '36px',
                                    lineHeight: (isMobile || isTablet) ? '32px' : '48px',
                                    fontFamily: 'Bitter',
                                    marginBottom: (isMobile || isTablet) ? '16px' : '24px',
                                }}
                            >
                                {lang.HomePage.about.title}
                            </h3>
                            <p
                                style={{
                                    fontSize: (isMobile || isTablet) ? "16px" : "18px",
                                    lineHeight: (isMobile || isTablet) ? "24px" : '26px',
                                    color: '#404040',
                                    fontWeight: 400
                                }}
                            >
                                {lang.HomePage.about.description}</p>
                            {(!isTablet && !isMobile) &&
                                <div
                                    style={{fontSize: "0.9em", fontWeight: 700}}
                                    className="mb-4 mt-4"
                                >
                                    <Button
                                        onClick={() => {
                                            navigate('/about')
                                        }}
                                        style={{
                                            border: 0,
                                            textAlign: 'center',
                                            width: '210px',
                                            height: '40px',
                                            borderRadius: '24px',
                                            backgroundColor: '#0A0A0A',
                                            fontWeight: 600,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {lang.HomePage.about.more}
                                    </Button>
                                </div>
                            }
                        </Col>
                        <Col md={5} sm={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <Swiper
                                style={{
                                    width: isTablet ? '340px' : '470px', 
                                    display: 'flex', 
                                    justifyContent: 'center'
                                    
                                }}
                                keyboard={{
                                    enabled: true
                                }}
                                pagination={{
                                    dynamicBullets: true,
                                    clickable: true,
                                    renderBullet: (index, className) => {
                                        // return `<span class="${className} custom-bullets" style="width: 15px; height: 15px; background-color: red; border-radius: 50%;"></span>`;
                                        // return `<span class="${className}"></span>`;
                                        return `
                                            <span class="${className}" style="
                                                height: 15px;
                                                background-color: rgba(255, 255, 255);
                                                border-radius: 50%;
                                                margin: 0 6px;
                                                transition: background-color 0.3s;
                                            "></span>
                                        `;
                                    },
                                }}
                                navigation={{
                                    nextEl: ".custom-swiper-button-next",
                                    prevEl: ".custom-swiper-button-prev",
                                }}
                                modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard, Autoplay]}
                                slidesPerView={1}
                            >
                                {lang.HomePage.image.map((about, index) => (
                                    <SwiperSlide key={index}>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <img
                                                style={{
                                                    height: isMobile ? '300px' : isTablet ? "328px" : "440px",
                                                    width: "100%",
                                                    objectFit: "cover",
                                                    borderRadius: '24px'
                                                }}
                                                alt={"about-section-image"}
                                                src={about.image}
                                            />
                                        </div>
                        
                                    </SwiperSlide>
                                ))}

                                <div
                                    className="custom-swiper-button-next"
                                    style={{
                                        position: "absolute",
                                        top: "45%",
                                        right: "0",
                                        zIndex: 10,
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        padding: "5px"
                                    }}
                                >
                                    <Icon icon="uiw:right-circle" height="30"  style={{ color: "#fff" }} />
                                </div>
                                <div
                                    className="custom-swiper-button-prev"
                                    style={{
                                        position: "absolute",
                                        top: "45%",
                                        left: "-0",
                                        zIndex: 10,
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        padding: "5px"
                                    }}
                                >
                                    <Icon icon="uiw:right-circle" height="30"  style={{ color: "#fff" }} rotate={'180deg'} />
                                </div>
                            </Swiper>
                        </Col>
                    </Row>
                    {(isTablet || isMobile) &&
                        <Row>
                            <Col md={12} className={"d-flex flex-column align-items-center"}>
                                <div
                                    style={{fontSize: "0.9em", fontWeight: 700}}
                                    className="mb-4 mt-4"
                                >
                                    <Button
                                        onClick={() => {
                                            navigate('/about')
                                        }}
                                        style={{
                                            border: 0,
                                            textAlign: 'center',
                                            width: '210px',
                                            height: '40px',
                                            borderRadius: '24px',
                                            backgroundColor: '#0A0A0A',
                                            fontWeight: 600,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {lang.HomePage.about.more}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        )
    }

    const renderOurProductsSection = () => {
        return (
            <Container
                style={{
                    marginTop: isMobile ? '48px' : isTablet ? '48px' : '80px',
                    marginBottom: isMobile ? '48px' : isTablet ? '48px' : '80px',
                }}
            >
                <Row style={{marginBottom: '0px'}}>
                    <Col
                        className={
                            `${(isMobile || isTablet) ? "align-items-start" : ""}`
                        }
                        md={7}
                    >
                        <h3
                            style={{
                                fontSize: (isMobile || isTablet) ? '24px' : '36px',
                                fontWeight: 'Bold',
                                fontFamily: 'Bitter',
                                lineHeight: (isMobile || isTablet) ? '24px' : '26px',
                                marginBottom: '16px',
                            }}
                        >
                            {lang.HomePage.product.title}
                        </h3>
                        <p 
                            style={{
                                color: Palette.THEME_TEXT_MAIN, 
                                fontSize: (isMobile || isTablet) ? '14px' : '16px', 
                                fontWeight: 400, 
                                lineHeight: '26px'
                            }}
                            className={(isMobile || isTablet) ? "text-left" : ""}
                        >
                            {lang.HomePage.product.description}
                        </p>
                    </Col>
                </Row>

                <Row className={"mt-1 mb-5"}>
                    {lang.HomePage.list.map((v, index) => {
                        return (
                            <>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    style={{
                                        height: (isMobile || isTablet) ? "fit-content" : "auto",
                                        // marginBottom: "1rem",
                                        padding: (isMobile || isTablet) ? "12px" : '36px'
                                    }}
                                >
                                    <Link to={v?.link}>
                                        <div style={{ position: 'relative', width: '100%',}}>
                                            {
                                                <img
                                                    style={{
                                                        height: '100%',
                                                        width: "100%",
                                                        borderRadius: '24px',
                                                        objectFit: "cover"
                                                    }}
                                                    src={v.image}
                                                />
                                            }
                                            <div 
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    borderRadius: '24px',
                                                    background: `linear-gradient(180deg, rgba(10, 10, 10, 0) 50%, rgba(10, 10, 10, 0.48) 80%)`,
                                                }}
                                            ></div>
                                            <h4 style={{
                                                fontSize: (isMobile || isTablet) ? "20px" : "24px",
                                                fontWeight: 700,
                                                lineHeight: (isMobile || isTablet) ? "28px" : '32px',
                                                fontFamily: 'Bitter',
                                                color: 'white',
                                                position: 'absolute',
                                                bottom: (isMobile || isTablet) ? "24px" : '32px',
                                                left: (isMobile || isTablet) ? "20px" : '32px',
                                            }}>
                                                {v?.title}
                                            </h4>
                                        </div>
                                    </Link>
                                </Col>
                            </>
                        )
                    })}
                </Row>
            </Container>
        )
    }

    const renderWhyUs = () => {
        return (
            <>
                <Container
                    style={{
                        marginBottom: isMobile ? '48px' : isTablet ? '48px' : '80px',
                    }}
                >
                    <Row>
                        <Col
                            style={{
                                marginBottom: '36px'
                            }}
                            className={
                                "d-flex flex-column justify-content-center " +
                                `${isTablet ? "align-items-start" : ""}`
                            }
                            md={7}
                        >
                            <h3
                                style={{
                                    fontWeight: 'Bold',
                                    fontSize: isTablet ? "24px" : "36px",
                                    lineHeight: isTablet ? "32px" : '48px',
                                    fontFamily: 'Bitter',
                                    marginBottom: '16px',
                                }}
                            >
                                {lang.HomePage.WhyUs.title}
                            </h3>
                            <p
                                style={{
                                    fontSize: 'isTablet ? "16px" : 18px',
                                    fontWeight: 400,
                                    lineHeight: isTablet ? "24px" : '26px',
                                    letterSpacing: '-0.014em',
                                }}
                            >
                                {lang.HomePage.WhyUs.description}
                            </p>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        {lang.HomePage.WhyUs.list.map((v) => {
                            return (
                                <>
                                    <Col 
                                        xs={12}
                                        sm={6}
                                        lg={4}
                                    >
                                        <div style={{margin: '0 36px 36px 0'}}>
                                            <div
                                                style={{
                                                    marginBottom: '12px',
                                                    width: '100%',
                                                    height: '40px',
                                                    padding: '8px 0px 0px 0px',
                                                    gap: '10px',
                                                    borderRadius: '12px',
                                                }}
                                            >
                                                <img src={v?.image}></img>
                                            </div>
                                            <div>
                                                <h5
                                                    style={{
                                                        fontWeight: 'Bold',
                                                        fontSize: isTablet ? '18px' : '20px',
                                                        lineHeight: isTablet ? '26px' : '28px',
                                                    }}
                                                >
                                                    {v.title}
                                                </h5>
                                            </div>
                                            <div>
                                                <p
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: isTablet ? '16px' : '18px',
                                                        lineHeight: isTablet ? '24px' : '26px',
                                                    }}
                                                >
                                                    {v.description}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>
                </Container>
            </>
        )
    }

    return (
        <div className={"d-flex flex-column"}>
            {renderHeroSection()}
            {renderAboutUsSection()}
            {renderOurProductsSection()}
            {renderWhyUs()}
        </div>
    )
}
