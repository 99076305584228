import { Icon } from "@iconify/react"
import { React, useState } from "react"
import { Button, Col, Container, Dropdown, Image, Row } from "react-bootstrap"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import { Link, useLocation, useNavigate } from "react-router-dom"
import logo from "../../assets/logo.png"
import langEn from "../utils/lang/en"
import langId from "../utils/lang/id"

export default function Header({isTablet, isMobile, lang, setLang}) {
    const [showMenu, setShowMenu] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [expand, setExpand] = useState(false)
    const [openIndex, setOpenIndex] = useState(null);
    const [open, setOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    
    const toggleDropdownLang = () => {
        setOpen(!open);
    };

    const arrowIconStyle = {
        transition: 'transform 0.3s',
        color: 'black',
    };

    const handleToggle = () => {
        setShowDropdown(prev => !prev);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setShowDropdown(false); // Close the dropdown after navigation
    };

    const renderLangID = (
        <>
            <Icon icon={'twemoji:flag-indonesia'}/>
            <span style={{marginLeft: 3}}>ID</span>
        </>
    )

    const renderLangEN = (
        <>
            <Icon icon={'twemoji:flag-united-kingdom'}/>
            <span style={{marginLeft: 3}}>EN</span>
        </>
    )

    const renderLangIDMobile = (
        <>
            <span style={{marginLeft: 3}}>Bahasa Indonesia</span>
        </>
    )

    const renderLangENMobile = (
        <>
            <span style={{marginLeft: 3}}>English</span>
        </>
    )

    const renderLangIDMobile_1 = (
        <>
            <span style={{marginLeft: 3}}>ID</span>
        </>
    )

    const renderLangENMobile_1 = (
        <>
            <span style={{marginLeft: 3}}>EN</span>
        </>
    )

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const mobileNav = () => {
        return (
            <>
                <Navbar
                    collapseOnSelect
                    style={{
                        backgroundColor: 'transparent', 
                        position: 'fixed',
                        zIndex: 700,
                        width: '100%',
                        top: 0,
                    }}
                    key={expand}
                    expand={false}
                >
                    <Container fluid>
                        <Navbar.Brand href="/">
                            {" "}
                            <Image style={{width: 174, height: 48, marginRight: 10}} src={logo}></Image>
                        </Navbar.Brand>


                        <Navbar.Toggle
                            onClick={() => {
                                setShowMenu(!showMenu)
                            }}
                            style={{border: "none", color: "#fff"}}
                            aria-controls="basic-navbar-nav"
                        >
                            <Icon icon="heroicons:bars-3" width="28" height="28"  style={{color: 'black'}} />
                        </Navbar.Toggle>


                        <Offcanvas placement="end" show={showMenu} style={{maxWidth: '350px'}}>
                            <Offcanvas.Header
                                style={{
                                    backgroundColor: 'transparent',
                                    paddingTop: 20,
                                    fontSize: 18,
                                    width: "100%"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        fontWeight: 600,
                                        fontSize: 18,
                                        color: "white",
                                        alignItems: "center",
                                        verticalAlign: "center"
                                    }}
                                >
                                    <div style={{flex: 1}}>
                                        <Link className="text-decoration-none text-white" to={"/"}>
                                            <Image
                                                style={{width: 174, height: 48, marginRight: 10}}
                                                src={logo}
                                            ></Image>
                                        </Link>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => {
                                                setShowMenu(!showMenu)
                                            }}
                                            style={{color: "black", fontSize: 20}}
                                            variant="link"
                                        >
                                            <Icon icon="bitcoin-icons:cross-filled" width="28" height="28"  style={{color: "#404040"}} />
                                        </Button>
                                    </div>
                                </div>
                                {/* borderBottom: '1px solid #EDEDED', */}
                            </Offcanvas.Header>
                            <Offcanvas.Body style={{backgroundColor: 'transparent', padding: 0,}}>
                                <Nav>
                                    {lang.MENU_HEADER.map((obj, index) => {
                                        if (index === 3) return null;
                                        return (
                                            <div style={{borderBottom: '1px solid #EDEDED'}}>
                                                <div
                                                // onClick={() => obj.path}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        justifyContent: 'space-between',
                                                        padding: '12px 16px',
                                                    }}
                                                >
                                                    <span onClick={() => {
                                                            if (index === 1) {
                                                                toggleDropdown(index)
                                                            } else {
                                                                navigate(obj.path); 
                                                                scrollToTop()
                                                            }
                                                        }} 
                                                        style={{ fontWeight: 600, fontSize: '16px', color: '#0A0A0A', flex: 1 }}
                                                    >
                                                        {obj.name}
                                                    </span>
                                                    {obj.submenu && obj.submenu.length > 0 && (
                                                        <Icon
                                                            onClick={() => toggleDropdown(index)}
                                                            icon="mingcute:down-line"
                                                            width="16"
                                                            style={{ 
                                                                ...arrowIconStyle,
                                                                transform: openIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                {obj.submenu && obj.submenu.length > 0 && openIndex === index && (
                                                <div>
                                                    {obj.submenu.map((subItem, subIndex) => (
                                                        <div key={subIndex} style={{ fontWeight: 400, fontSize: '16px', padding: '12px 16px 12px 36px',}}>
                                                            <Link
                                                                style={{
                                                                    textDecoration: 'none',
                                                                    color: '#404040', 
                                                                }}
                                                                to={subItem.path}
                                                            >
                                                                {subItem.name}
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </Nav>
                                <div style={{borderBottom: '1px solid #EDEDED'}}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            justifyContent: 'space-between',
                                            padding: '12px 16px',
                                        }}
                                    >
                                        <span 
                                            style={{ 
                                                fontWeight: 600, 
                                                fontSize: '16px', 
                                                color: '#0A0A0A', 
                                                flex: 1 
                                            }}
                                        >
                                            {lang === langId ? renderLangIDMobile_1 : renderLangENMobile_1}
                                        </span>
                                        <Icon
                                            onClick={toggleDropdownLang}
                                            icon="mingcute:down-line"
                                            width="16"
                                            style={{ 
                                                ...arrowIconStyle,
                                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                            }}
                                        />
                                    </div>
                                    {open && (
                                        <div>
                                            <div className="nav-lang" id="dropdown-language-full" style={{color: '#0A0A0A'}}>
                                                <div onClick={() => setLang(langId)} style={{padding: '12px 16px 12px 36px', cursor: 'pointer'}}>
                                                    {renderLangIDMobile}
                                                </div>
                                                <div onClick={() => setLang(langEn)} style={{padding: '12px 16px 12px 36px', cursor: 'pointer'}}>
                                                    {renderLangENMobile}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <Nav>
                                    {lang.MENU_HEADER.map((obj, index) => {
                                        if (index !== 3) return null;
                                        return (
                                            <div style={{padding: '16px'}}>
                                                <div
                                                // onClick={() => obj.path}
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                        height: '40px',
                                                        borderRadius: '24px',
                                                        backgroundColor: '#E60000',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                    onClick={() => {
                                                        navigate(obj.path); 
                                                        scrollToTop()
                                                    }} 
                                                >
                                                    <span 
                                                        style={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', color: '#FFFFFF', flex: 1 }}
                                                    >
                                                        {obj.name}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Nav>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Container>
                </Navbar>
            </>
        )
    }

    const normalNav = () => {
        return (
            <>
                <div
                    style={{
                        color: "white",
                        background: "transparent",
                        width: "100%",
                        position: "fixed",
                        top: 0,
                        zIndex: 700
                    }}
                >
                    <Container
                        style={{flexDirection: "column", alignItems: "flex-start"}}
                    >
                        <Row style={{height: '100px',}} className="d-flex align-items-center justify-content-between">
                            <Col
                                className={
                                    "d-flex align-items-center justify-content-start"
                                }
                                md={5}
                            >
                                <Link className="text-decoration-none text-white" to={"/"} onClick={scrollToTop}>
                                    <Image
                                        style={{width: 210, marginRight: 60}}
                                        src={logo}
                                    ></Image>
                                </Link>
                            </Col>
                            <Col
                                className={
                                    "d-flex align-items-center justify-content-end"
                                }
                                md={7}
                            >
                                {!isTablet && !isMobile ? (
                                    <>
                                        {lang.MENU_HEADER.map((obj, index) => {
                                            if (index === 3) return null;
                                            return !obj.submenu ? (
                                                <div
                                                    className={"ms-3 me-3"}
                                                    style={{
                                                        fontWeight: 600,
                                                        color: location.pathname === obj.path ? "#E60000" : "#0A0A0A",
                                                        cursor: "pointer",
                                                        textDecoration: location.pathname === obj.path ? "underline" : "none",
                                                        textUnderlineOffset: "10px",
                                                    }}
                                                    onClick={() => {navigate(obj.path); scrollToTop()}}
                                                >
                                                    {obj.name}
                                                </div>
                                            ) : (
                                                <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                                                    <div
                                                        style={{display: "flex", flexDirection: "row"}}
                                                        onClick={handleToggle}
                                                    >
                                                        <div
                                                            className={""}
                                                            style={{
                                                                fontWeight: 600,
                                                                color:
                                                                    location.pathname === obj.path || obj.submenu.some(sub => location.pathname === sub.path)
                                                                        ? "#E60000" 
                                                                        : "#0A0A0A",
                                                                cursor: "pointer",
                                                                textDecoration:
                                                                    location.pathname === obj.path || obj.submenu.some(sub => location.pathname === sub.path)
                                                                        ? "underline"
                                                                        : "none",
                                                                textUnderlineOffset: "10px",
                                                            }}
                                                            // onClick={() => {navigate(obj.path)}}
                                                        >
                                                            {obj.name} <Icon icon="heroicons:chevron-down-16-solid"/>
                                                        </div>
                                                        {/* <Dropdown.Toggle
                                                            className="py-0 custom-dropdown-nav"
                                                            style={{
                                                                color: 'black',
                                                                cursor: "pointer",
                                                            }}
                                                            variant="link"
                                                        /> */}
                                                    </div>
                                                    <Dropdown.Menu
                                                        style={{
                                                            borderRadius: '16px',
                                                            backgroundColor: '#0A0A0A',
                                                            border: 0,
                                                            padding: '1rem 0',
                                                            boxShadow:
                                                                " 0px 4px 40px 0px rgba(18, 18, 18, 0.08)"
                                                        }}
                                                    >
                                                        {
                                                            obj.submenu.map((sub, index2) => {
                                                                return (
                                                                    <>
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                handleNavigation(sub.path);
                                                                                scrollToTop()
                                                                            }}
                                                                            className={`dropdown-big-custom`}
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '18px',
                                                                                lineHeight: '26px',
                                                                                color: "white",
                                                                                cursor: "pointer",

                                                                            }}
                                                                        >
                                                                            {sub.name}
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )
                                                            })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )
                                        })}
                                    <NavDropdown
                                        className="nav-lang"
                                        id="dropdown-language-full"
                                        title={
                                            <span
                                                style={{
                                                    fontWeight: 600,
                                                    color: "#0A0A0A",
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {lang === langId ? renderLangID : renderLangEN}
                                                <Icon
                                                    icon="heroicons:chevron-down-16-solid"
                                                    style={{
                                                        marginLeft: '8px',
                                                    }}
                                                />
                                            </span>
                                        }
                                    >
                                        <NavDropdown.Item href="javascript:;" onClick={() => setLang(langId)}>
                                            {renderLangID}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="javascript:;" onClick={() => setLang(langEn)}>
                                            {renderLangEN}
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    {lang.MENU_HEADER.map((obj, index) => {
                                        if (index !== 3) return null;
                                        return !obj.submenu ? (
                                            <div
                                                className={"ms-3 me-3"}
                                                style={{
                                                    textAlign: 'center',
                                                    width: '140px',
                                                    height: '40px',
                                                    borderRadius: '24px',
                                                    backgroundColor: '#E60000',
                                                    fontWeight: 600,
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                                onClick={() => {navigate(obj.path); scrollToTop()}}
                                            >
                                                {obj.name}
                                            </div>
                                        ) : ""
                                    })}
                                    </>
                                ) : (
                                    <>
                                        <Icon
                                            icon="heroicons:bars-3" width="64" height="64"  style={{color: 'black'}}
                                            onClick={() => setShowMenu(!showMenu)}
                                        />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }

    const handleRender = () => {
        return (isMobile || isTablet) ? mobileNav() : normalNav()
    }

    return handleRender()
}
