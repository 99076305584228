import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";

export default function AboutPage() {
    const [isMobile, isTablet, lang, setLang] = useOutletContext()

    const [showModal, setShowModal] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);

    const handleOpenPDF = (pdfPath) => {
        console.log("Opening PDF:", pdfPath);
        setPdfFile(pdfPath);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPdfFile(null);
    };

    const renderAboutUsSection = () => {
        return (
            <>
                <Container 
                    style={{
                        marginTop: isMobile ? '160px' : isTablet ? '160px' : '160px',
                        marginBottom: isMobile ? '48px' : isTablet ? '48px' : '80px',
                    }}
                >
                    <Row>
                        <Col md={12} lg={7} xl={7} sm={12}>
                            <h3
                                style={{
                                    fontSize: (isMobile || isTablet) ? '28px' : "36px",
                                    lineHeight: (isMobile || isTablet) ? '36px' : '48px',
                                    fontFamily: 'Bitter',
                                    fontWeight: 600,
                                    color: '#0A0A0A',
                                    marginBottom: '24px',
                                }}
                            >
                                {lang.AboutPage.hero.title}
                            </h3>
                            <p
                                style={{
                                    alignSelf: "stretch",
                                    color: "#404040",
                                    fontWeight: 400,
                                    fontSize: (isMobile || isTablet) ? '16px' : '18px',
                                    lineHeight: (isMobile || isTablet) ? '24px' : '26px',
                                }}
                            >
                                {lang.AboutPage.hero.description}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        
                    </Row>
                </Container>
            </>
        )
    }
    const renderPhotosSection = () => {
        return (
            <>
                <Container
                    style={{
                        marginTop: (isMobile || isTablet) ? '48px' : '80px',
                        marginBottom: isMobile || isTablet ? '48px' : '80px',
                    }}
                >
                    <Row>
                        <Col xs={12} sm={6} lg={6} style={{marginBottom: isMobile || isTablet ? '12px' : '24px',}}>
                            <img
                                src={lang.AboutPage.images.image_1}
                                style={{
                                    width: '100%',
                                    // height: (isMobile || isTablet) ? '348px' : '596px',
                                    borderRadius: '24px',
                                    objectFit: 'contain',
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={6} lg={6}>
                            <Row className="gx-3.5" style={{marginBottom: isMobile || isTablet ? '12px' : '24px',}}>
                                <Col xs={6} sm={6}>
                                    <img
                                        src={lang.AboutPage.images.image_2}
                                        style={{
                                            width: '100%',
                                            // width: (isMobile || isTablet) ? '165px' : '284px',
                                            // height: (isMobile || isTablet) ? '172px' : '274px',
                                            borderRadius: '24px',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Col>
                                <Col xs={6} sm={6}>
                                    <img
                                        src={lang.AboutPage.images.image_4}
                                        style={{
                                            width: '100%',
                                            // height: (isMobile || isTablet) ? '172px' : '274px',
                                            borderRadius: '24px',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <div>
                                        <img
                                            src={lang.AboutPage.images.image_3}
                                            style={{
                                                width: '100%',
                                                // width: isMobile ? '348px' : isTablet ? '165px' : '274px',
                                                // height: isMobile ? '348px' : isTablet ? '165px' : '274px',
                                                borderRadius: '50%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    const renderVisionSection = () => {
        return (
            <>
                <Container 
                    style={{
                        marginTop: isMobile ? '48px' : isTablet ? '48px' : '80px',
                        marginBottom: isMobile ? '48px' : isTablet ? '48px' : '80px',
                    }}
                >
                    <Row>
                        <Col md={12} lg={7} xl={7} sm={12}>
                            <h3
                                style={{
                                    fontSize: (isMobile || isTablet) ? "24px" : "32px",
                                    lineHeight: (isMobile || isTablet) ? "32px" : '48px',
                                    fontFamily: 'Bitter',
                                    fontWeight: 600,
                                    color: '#0A0A0A',
                                    marginBottom: '24px',
                                }}
                            >
                                {lang.AboutPage.visionMission.title}
                            </h3>
                            <p
                                style={{
                                    alignSelf: "stretch",
                                    color: "#404040",
                                    fontWeight: 400,
                                    fontSize: (isMobile || isTablet) ? "16px" : '18px',
                                    lineHeight: (isMobile || isTablet) ? "24px" : '26px',
                                }}
                            >
                                {lang.AboutPage.visionMission.description}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    const renderCertificateSection = () => {
        return (
            <>
                <Container 
                    style={{
                        marginTop: isMobile ? '48px' : isTablet ? '48px' : '80px',
                        marginBottom: isMobile ? '48px' : isTablet ? '48px' : '80px',
                    }}
                >
                    <Row>
                        <Col md={12} lg={7} xl={7} sm={12}>
                            <h3
                                style={{
                                    fontSize: (isMobile || isTablet) ? "24px" : "32px",
                                    lineHeight: (isMobile || isTablet) ? "32px" : '48px',
                                    fontFamily: 'Bitter',
                                    fontWeight: 600,
                                    color: '#0A0A0A',
                                    marginBottom: '24px',
                                }}
                            >
                                {lang.AboutPage.certificate.title}
                            </h3>
                        </Col>
                    </Row>
                    <Row className="d-flex flex-row">
                        {lang.AboutPage.certificate.list.map((obj, index) => (
                            <Col className="mb-2 mt-2 ml-auto d-flex flex-row" lg={3} xl={3} md={4} sm={12} xs={12} key={index}>
                                <div style={{ marginRight: '36px' }}>
                                    <div className="d-flex flex-row align-items-center justify-content-center" style={{marginBottom: '4px'}} onClick={() => handleOpenPDF(obj.pdf)}>
                                        <img
                                            src={obj.image}
                                            style={{
                                                width: '100%',
                                                height: '320px',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex flex-row align-items-center justify-content-center">
                                        <p 
                                            style={{
                                                fontSize: (isMobile || isTablet) ? "16px" : '18px',
                                                fontWeight: 500,
                                                lineHeight: (isMobile || isTablet) ? "24px" : '26px',
                                            }}
                                        >
                                            {obj.name}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </>
        )
    }
    return (
        <div className={"d-flex flex-column"}>
            {renderAboutUsSection()}
            {renderPhotosSection()}
            {renderVisionSection()}
            {renderCertificateSection()}

            <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Body>
                    {pdfFile && (
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                            <Viewer fileUrl={pdfFile} />
                        </Worker>
                    )}
                </Modal.Body>
            </Modal>

        </div>
    )
}
